<template>
  <div class="row justify-content-center">
    <customer-header />
    <vs-prompt
      color="success"
      title=""
      accept-text="Export Excel"
      cancel-text="ยกเลิก"
      @accept="exportExcelReport"
      @close="close"
      :active.sync="activePromptExport"
    >
      <div class="con-exemple-prompt">
        Export Report Excel
        <br /><br />
        <datepicker
          placeholder="วันที่เริ่มต้น เวลาเข้า"
          :format="DatePickerFormat"
          v-model="startDateReport"
          :click="onClickDate()"
        ></datepicker>
        <br />
        <datepicker
          placeholder="วันที่สิ้นสุด เวลาเข้า"
          :format="DatePickerFormat"
          v-if="startDateReport"
          v-model="endDateReport"
          :disabledDates="disabledDates"
        ></datepicker>
        <br />
        <p style="color: red" align="center">
          Export ได้มากที่สุดแค่ 31 วัน จากวันเริ่มต้น
        </p>
      </div>
    </vs-prompt>

    <vs-prompt
      color="primary"
      title=""
      accept-text="ค้นหา"
      cancel-text="ยกเลิก"
      @accept="listItems(1)"
      @close="close"
      :active.sync="activePromptSearch"
    >
      <div class="con-exemple-prompt">
        ค้นหาข้อมูลข้อมูลการเดินตรวจ

        <br /><br />
        <v-select
          label="text"
          placeholder="กรุณาเลือก"
          v-model="selected"
          @input="checkOption"
          :options="optionsLists"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        />

        <vs-input
          v-if="inputSearch === 1"
          type="text"
          icon="search"
          placeholder="คำค้นหา"
          v-model="searchText"
        />
        <vs-input
          v-if="inputSearch === 2"
          type="date"
          icon="event"
          v-model="searchText"
        />
        <!-- <vs-alert :active="!validName" color="danger" icon="new_releases">
          กรุณากรอกข้อมูลให้ครบถ้วน
        </vs-alert> -->
      </div>
    </vs-prompt>

    <vs-popup
      class="holamundo"
      title="รายละเอียด"
      :active.sync="activePromptDetail"
      v-if="objectDetail"
    >
      <div class="vx-row">
        <div class="vx-col lg:w-1/1 w-full">
          <vx-card class="mb-base">
            <table class="modaltable">
              <tr>
                <td class="font-semibold">รายละเอียด</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.desc | checkString }}
                </td>
              </tr>
              <tr>
                <td class="font-semibold">เวลาสแกน</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.scanDateTime | formatDate}}
                </td>
              </tr>
              <tr></tr>
                <td class="font-semibold">พิกัดการเดิน</td>
                <td style="padding-left: 50px">
                  <a target="_blank" :href="objectDetail.map_url">
                    <feather-icon
                      icon="MapIcon"
                      title=""
                      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                  /></a>
                </td>
              </tr>
              <tr
>
                <td style="">
                  <div
                    style="
                      border: 1px solid #3f4652;
                      padding: 8px;
                      background-color: antiquewhite;
                      margin-top: 10px;max-width:180px; margin-bottom:20px;
                    "
                    v-if="Array.isArray(objectDetail.photos) && objectDetail.photos.length > 0"
                  >
                    <div
                      style="margin-top: 10px;border: 8px solid #3f4652;"
                      :key="indextr_image"
                      v-for="(image, indextr_image) in objectDetail.photos"
                    >
                      <img
                        v-img
                        style="width: 100%;"
                        :src="image"
                        class="img-fluid"
                        :key="indextr_image"
                      />
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <td class="font-semibold">id</td>
                <td style="padding-left: 50px">
                  {{ objectDetail.key | checkString }}
                </td>
              </tr>
            </table>
          </vx-card>
        </div>
      </div>
    </vs-popup>

    <vs-row vs-type="flex" vs-justify="center">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
        <datepicker id="start-date-report" placeholder="วันที่เริ่มต้น"
              :format="DatePickerFormat"
              v-model="startDateReport"
              style="width: 100%;padding-right: 10px;"
              :click="onClickDate()"></datepicker>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" v-if="startDateReport">
        <datepicker id="start-date-report" placeholder="วันที่สิ้นสุด"
              :format="DatePickerFormat"
              v-model="endDateReport"
              :disabledDates="disabledDates"
              style="width: 100%;padding-right: 10px;"
              :click="onClickDate()"></datepicker>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3">
          <vs-button
              @click="listItems(1)"
              color="primary"
              type="filled"
              icon-pack="feather"
              icon="icon-search"
              :disabled="!canSearch"
              style="margin-left: 5px;float: left;"
            >
            ค้นหา
          </vs-button>
          <vs-button
            :loading="downloadLoading"
            @click="exportExcelReport"
            :disabled="!canExport"
            color="success"
            type="filled"
            style="margin-left: 5px;float: left;"
            >
            <feather-icon
              icon="DownloadIcon"
              title="Export Excel"
              svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
            />
            Export
          </vs-button>
        <vs-button
              v-if="canReset"
              @click="resetSearch"
              color="dark"
              type="filled"
              icon-pack="feather"
              style="margin-left: 5px;float: left;"
            >
            Reset
          </vs-button>
      </vs-col>
    </vs-row>
    <vs-card style="margin-top: 10px">
      <div>
        <vs-table stripe :data="items">
          <template slot="thead" style="margin-top: 100px">
            <vs-th>รูป</vs-th>
            <vs-th>รปภ.</vs-th>
            <vs-th>เวลา</vs-th>
            <vs-th>รอบ</vs-th>
            <vs-th>เวลาเริ่มตรวจ</vs-th>
            <vs-th>เวลาตรวจเสร็จ</vs-th>
            <vs-th>รายละเอียด</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].member_verify_photo">
                <img
                  v-img
                  style="
                  object-fit: cover;
                  border-radius:50%;
                  width: 60px;
                  height: 60px;
                  "
                  :src="data[indextr].member_verify_photo"
                  class="img-fluid"
                  :key="indextr"
                />
              </vs-td>
              <vs-td :data="data[indextr].member_name">
                {{ data[indextr].member_name }} <span v-if="data[indextr].is_cancel" style="color:#ae1a1a;">(ยกเลิกการตรวจ)</span>
              </vs-td>
              <vs-td :data="data[indextr].create_date">
                {{ data[indextr].create_date | formatDate }}
              </vs-td>
              <vs-td :data="data[indextr].round">
                {{ data[indextr].round }}
              </vs-td>
              <vs-td :data="data[indextr].walk_start_datetime">
                {{ data[indextr].walk_start_datetime | formatDate }}
              </vs-td>
              <vs-td :data="data[indextr].walk_end_datetime">
                {{ data[indextr].walk_end_datetime | formatDate }}
              </vs-td>
              <vs-td :data="data[indextr].walk_end_datetime">
                <a target="_blank" :href="getMapLink(data[indextr].key)">
                 <feather-icon
                          icon="EyeIcon"
                          title="ดูข้อมูลเพิ่มเติม"
                          svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                        /></a>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div>
          <br />
          <vs-row>
            <vs-col class="text-right">
              <div class="btn-group">
                <vs-button
                  type="border"
                  style="padding-top: 2px"
                  :disabled="page == 1"
                  @click="previousData"
                  >Prev</vs-button
                >
                <vs-button
                  type="border"
                  :disabled="items.length < show_per_page"
                  @click="nextData"
                  >Next</vs-button
                >
              </div>
            </vs-col>
          </vs-row>

          <!--
          <paginate
            v-model="page"
            :page-count="total"
            :page-range="3"
            :margin-pages="2"
            :click-handler="inputData"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'page-item'"
          >
          </paginate> -->
        </div>
      </div>
    </vs-card>
    <vs-card style="margin-top: 5px">
      <!-- <h5>จำนวนข้อมูลทั้งหมด {{ totalItems }}</h5> -->
    </vs-card>
  </div>
</template>

<script>
import Vue from "vue";
import VueCryptojs from "vue-cryptojs";
import VueCookies from "vue-cookies";
import { db } from "../store/firebaseDb";
const moment = require("moment");
require("moment/locale/th");
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import CustomerHeader from "@/layouts/components/custom/CustomerHeader.vue";
console.log("lang", lang);

import Paginate from "vuejs-paginate";
import { functions } from "firebase";
import vSelect from "vue-select";

import VueImg from "v-img";
import ExcelJS from 'exceljs';
import { promises as fs } from 'fs';
import axios from 'axios'
Vue.component("paginate", Paginate);

Vue.use(VueCryptojs);
Vue.use(VueCookies);
Vue.use(VueImg);

Vue.component("customer-header", CustomerHeader);

moment.locale("th");
Vue.use(require("vue-moment"), {
  moment,
});

Vue.filter("formatDate", function (value) {
  if (value) {
    const myDate = new Date(value.seconds * 1000); // date object
    return moment(String(myDate)).format("DD/MM/YYYY HH:mm:ss");
  }
  return "-";
});
Vue.filter("cardMissing", function (value) {
  if (value === false) {
    return "มีบัตร";
  } else {
    return "บัตรหาย";
  }
});
Vue.filter("convertCloseHome", function (value) {
  if (value === true) return "ปิดบ้าน";
  return "-";
});
Vue.filter("convertVip", function (value) {
  if (value === true) return "วีไอพี";
  return "-";
});
Vue.filter("checkString", function (value) {
  if (value) return value;
  return "-";
});
console.log(Vue.moment().locale());
export default {
  name: "ManageTransactionsCorp",
  components: {},
  data() {
    return {
      roleInfo: {},
      userInfo: null,
      language: "en",
      languages: lang,
      houseInfo: {},
      items: [],
      itemsTran: [],
      // pagination
      total: 0, // จำนวนหน้า
      show_per_page: 10,
      page: 1,
      // totalItems: 0, // จำนวนข้อมูลทั้งหมด
      isLoad: true,
      // lastItem: null,
      searchText: "",
      activePromptSearch: false,
      activePromptDetail: false,
      selected: "",
      optionsLists: [
        { text: "เลือกวันที่", value: "create_date" },
        // { text: "เลขที่ติดต่อ", value: "contact_address_name" },
        // { text: "ทะเบียนรถ", value: "license_plate_id" },
      ],
      inputSearch: 0,
      objectDetail: null,
      activePromptExport: false,
      startDateReport: "",
      endDateReport: "",
      DatePickerFormat: "dd/MM/yyyy",
      dateStart: null,
      disabledDates: {},
      itemsReport: [],
      additionFields: [],
      customer: {},

      headerTitle: [
        "วันที่",
        "รอบ",
        "เวลาสแกน",
        "จุดตรวจ",
        "รปภ.",
        "เวลาเริ่มตรวจ",
        "เวลาตรวจเสร็จ",

        "สถานะ",
        "รายละเอียด",
        "ละติจูด",
        "ลองจิจูด",

        "รูปเหตุการณ์.",
        "รูป รปภ.",
      ],

      headerVal: [
        "create_date",
        "round",
        "scanDateTime",
        "point_name",
        "member_name",
        "walk_start_datetime",
        "walk_end_datetime",

        "status",
        "desc",
        "latitude",
        "longitude",

        "photos",
        "member_verify_photo",
      ],
      headerTitleReport: [
        "วันที่",
        "เวลา",
        "รอบ",
        "ผลัด",
        "รูปผู้ตรวจ",
        "เวลาสแกน",
        "จุดตรวจ",
        "สถานะ",
        "รายละเอียด",

        "ละติจูด",
        "ลองจิจูด",
        "เวลาเริ่มตรวจ",
        "เวลาตรวจเสร็จ",
        "รูปเหตุการณ์.",

      ],

      headerValReport: [
        "create_date",
        "time",
        "round",
        "member_name",
        "member_verify_photo",
        "scanDateTime",
        "point_name",
        "status",
        "desc",

        "latitude",
        "longitude",
        "walk_start_datetime",
        "walk_end_datetime",
        "photos",

      ],
      downloadLoading: false,
      lastItemsList: {},
      limit: 0,
    };
  },
  computed: {
    validName() {
      return true;
    },
    changeStartDate(){
      console.log('endDateReport');
      this.endDateReport= null;
      return false

    },
    canSearch(){
      if(this.startDateReport && this.endDateReport){
        return true;
      }
    },
    canReset(){
      if(this.startDateReport || this.endDateReport){
        return true;
      }
    },
    canExport(){
      if(this.startDateReport && this.endDateReport){
        return true;
      }
    }

  },

  methods: {
    getMapLink(key) {
      return "/map?id=" + key;
    },
    async parseFile(url) {
          // console.log("[[parseFile]] url=" + url);
          try {
              const fetchResponse = await fetch(url);
              //  const ct = fetchResponse.headers.get("Content-Type");
              // console.log("ct=", ct);
              // const hd = fetchResponse.header();
              // console.log("hd=",hd);
              const responseText = await fetchResponse.text();

              //  if ( !this.checkString(responseText) ) {
              //   console.log("responseText=", responseText);
              //     responseText = "";
              //  }

              //  console.log("responseText=" + responseText);
              return responseText;

          } catch (ex) {
              console.log("Error in fetch");
              return "";
          }
      },
    dbRef() {
      ///customer/zgmx/scan/data/transaction_round/QGaiPjykDsMQbhHd7teC
      const customerName = $cookies.get("customer");
      const _ducumentPath = `customer/${customerName}/scan/data/transaction_round/`;
      return db.collection(_ducumentPath);
    },

    getDbRefWithCondition() {
      console.log("[[getDbRefWithCondition]]");
      let reference = this.dbRef();

      if (this.startDateReport) {
       reference = reference.where("create_date", ">=", this.setDateValue()[0])
       reference = reference.where("create_date", "<=", this.setDateValue()[1])
      }

      reference = reference.orderBy("create_date", "desc");
      const lastItem = this.lastItemsList[`${this.page - 1}`];

      if (lastItem !== undefined) {
        reference = reference.startAfter(lastItem).limit(this.show_per_page);
        // console.log(".startAt")
      }
      if (this.show_per_page !== null) {
        reference = reference.limit(this.show_per_page);
        // console.log(".limit")
      }
      return reference;
    },

    async listItems({ page = 1 }) {
      this.isLoad = true;
      this.page = page;
      console.log("PAGE [" + page + "] this page = " + this.page);
      this.getDbRefWithCondition().onSnapshot((snapshotChange) => {
        this.items = [];

        snapshotChange.forEach(async (doc) => {
          this.lastItemsList[`${page}`] = doc;

          const data = doc.data();

          // let date_full = "";
          // if (data.date_in && data.date_out) {
          //   date_full = this.strDate(
          //     data.walk_end_datetime.seconds,
          //     data.walk_start_datetime.seconds
          //   );
          // }

          const element = {
            key: doc.id,
            walk_end_datetime: data.walk_end_datetime,
            walk_start_datetime: data.walk_start_datetime,
            create_date: data.create_date,
            member_name: data.member_name,
            is_cancel: data.is_cancel,
            member_verify_photo: data.member_verify_photo,
            round: data.round,
          };

          this.itemsTran[doc.id] = [];
          await this.getTransaction(doc.id);

          this.items.push(element);
          this.isLoad = false;
        });
      });
    },

    async getTransaction(id) {
      console.log("getTransaction=" + id);

      const list = [];

      await this.dbRef()
        .doc(id)
        .collection("transaction")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            console.log('----------- data -----------')
            console.log(data);
            console.log("doc trans=" + doc.id);

            list[list.length] = {
              key: doc.id,
              name: data.name,
              is_ok: data.is_ok,
              isSkip: data.isSkip,
              status: data.isSkip === true ? "ข้ามจุด" : data.is_ok === true ? "เหตุการณ์ปกติ" : "เหตุการณ์ไม่ปกติ",
              desc: data.desc,
              scanDateTime: data.scanDateTime,
              latitude: data.latitude,
              longitude: data.longitude,
              photos: data.photos,
              map_url:
                "http://www.google.com/maps/place/" +
                data.longitude +
                "," +
                data.longitude,
            };

            console.log("list.length=" + list.length);
            this.itemsTran[id] = list;
          });
        });
    },

    isPrefixAddress(prefix_address, contact_address_name) {
      return prefix_address
        ? prefix_address + " / " + contact_address_name
        : contact_address_name;
    },

    isEmpty(data) {
      return data === undefined || data === null || data === "";
    },

    previousData: function () {
      this.page = this.page - 1;
      this.listItems({ page: this.page });
    },

    nextData: function () {
      this.page = this.page + 1;
      this.listItems({ page: this.page });
    },

    inputData: function (value) {
      this.page = value;
      this.listItems({ page: this.page });
    },

    close() {
      this.selected = "";
      this.searchText = "";
      this.inputSearch = 0;
    },

    openAddSearch() {
      this.selected = "";
      this.searchText = "";
      this.inputSearch = 0;
      this.activePromptSearch = true;
    },

    checkOption() {
      this.searchText = "";
      if (this.selected.value === "create_date") {
        this.inputSearch = 2;
      } else {
        this.inputSearch = 1;
      }
    },

    setDateValue() {
      const dateStart = this.startDateReport;
      const dateEnd = this.endDateReport;
      const date_formatS = new Date(dateStart);
      const date_formatE = new Date(dateEnd);
      const date_inS = new Date(date_formatS - 60 * 60 * 1000);
      const date_inE = new Date(date_formatE - 60 * 60 * 1000);

      date_inS.setHours(0, 0, 0, 0);
      const value = date_inS;

      date_inE.setDate(date_inE.getDate() + 1);
      date_inE.setHours(0, 0, 0, 0);
      const value2 = date_inE;
      // console.log(
      //   "setDateValue TYPE[" + type + "] s=" + value + " e=[" + value2 + "]"
      // );
      return [value, value2];
    },

    getExportFileName() {
      const customerName = $cookies.get("customer");
      const startDate = moment(new Date(this.startDateReport)).format(
        "DDMMYYYY"
      );
      const endDate = moment(new Date(this.endDateReport)).format("DDMMYYYY");
      return customerName + "-" + startDate + "-" + endDate;
    },

    strDate(begin, end) {
      const d = Math.trunc((end - begin) / 60 / 60 / 24);
      const h = Math.trunc((end - begin) / 60 / 60) % 24;
      const m = Math.trunc((end - begin) / 60) % 60;
      const s = (end - begin) % 60;
      let msg = "";
      if (d > 0) {
        msg += d + " วัน ";
      }
      if (h > 0) {
        msg += h + " ชั่วโมง ";
      }
      if (m > 0) {
        msg += m + " นาที ";
      }
      if (s > 0) {
        msg += s + " วินาที ";
      }
      if (s <= 0) {
        msg += s + " วินาที ";
      }
      // console.log("strDate TIME DAY["+d+"]HOUR["+h+"]minute["+m+"]second["+s+"] = "+msg);
      return msg;
    },

    async resetSearch() {
      this.searchText = "";
      this.items = [];
      this.total = 0;
      this.page = 1;
      this.isLoad = true;
      this.total = 0;
      this.startDateReport ='';
      this.endDateReport ='';
      // this.totalItems = 0;
      // this.lastItem = null;
      this.selected = "";
      this.listItems(1);
    },

    openDetail(objectData) {
      this.objectDetail = null;
      this.activePromptDetail = true;
      this.objectDetail = objectData;
    },

    openModalExcel() {
      this.startDateReport = null;
      this.endDateReport = null;
      this.activePromptExport = true;
    },

    async onClickDate() {
      if (this.startDateReport) {
        if (this.startDateReport !== this.dateStart) {
          this.dateStart = this.startDateReport;
          this.endDateReport = null;
          this.disabledDates = {
            to: new Date(
              new Date(this.startDateReport - 60 * 60 * 1000) - 8640000
            ),
            from: new Date(
              new Date(this.startDateReport - 60 * 60 * 1000).setDate(
                new Date(this.startDateReport - 60 * 60 * 1000).getDate() + 31
              ) - 8640000
            ),
          };
        }
      }
    },

    exportExcelReport() {
      this.downloadLoading = true;
      this.dbRef()
        .where("create_date", ">=", this.setDateValue()[0])
        .where("create_date", "<=", this.setDateValue()[1])
        .orderBy("create_date", "desc")
        .get()
        .then(async (query) => {
          this.itemsReport = [];

          // const data = doc.data();

          for (let i = 0; i < query.docs.length; i++) {
            const doc = query.docs[i];
            const data = doc.data();

            let date_full = "";

            if (data.walk_start_datetime && data.walk_end_datetime) {
              date_full = this.strDate(
                data.walk_start_datetime.seconds,
                data.walk_end_datetime.seconds
              );
            }

            this.itemsTran[doc.id] = [];
            await this.getTransaction(doc.id);

            const transactionItems = this.itemsTran[doc.id];

            if (transactionItems.length === 0) {
              const element = {
                key: doc.id,
                date_full: date_full,
                create_date: Vue.filter("formatDate")(data.create_date),
                member_name: data.is_cancel ? (data.member_name + " (ยกเลิกการตรวจ)") : data.member_name,
                is_cancel: data.is_cancel,
                member_verify_photo: data.member_verify_photo,
                round: data.round,
                walk_end_datetime: Vue.filter("formatDate")(
                  data.walk_end_datetime
                ),
                walk_start_datetime: Vue.filter("formatDate")(
                  data.walk_start_datetime
                ),
              };

              this.itemsReport.push(element);
            } else {
              for (let j = 0; j < transactionItems.length; j++) {
                const tItem = transactionItems[j];
                console.log("tItem.name=" + tItem.name);

                const element = {
                  key: doc.id,
                  date_full: date_full,
                  round: data.round,
                  member_name: data.is_cancel ? (data.member_name + " (ยกเลิกการตรวจ)") : data.member_name,
                  photos: tItem.photos,
                  scanDateTime: Vue.filter("formatDate")(tItem.scanDateTime),
                  create_date: Vue.filter("formatDate")(data.create_date),
                  member_verify_photo: data.member_verify_photo,
                  walk_end_datetime: Vue.filter("formatDate")(
                    data.walk_end_datetime
                  ),
                  walk_start_datetime: Vue.filter("formatDate")(
                    data.walk_start_datetime
                  ),
                  point_name: tItem.name,
                  status: tItem.isSkip === true ? "ข้ามจุด" : tItem.is_ok === true ? "เหตุการณ์ปกติ" : "เหตุการณ์ไม่ปกติ",
                  desc: tItem.desc,
                  latitude: tItem.latitude,
                  longitude: tItem.longitude,
                };

                this.itemsReport.push(element);
              }
            }
          }

          this.downloadLoading = false;

          console.log("======= Report ========");
          console.log("itemsReport.length = " + this.itemsReport.length);

          if (this.itemsReport.length) {
            console.log("this.headerTitle", this.headerTitle);
            console.log("this.headerVal", this.headerVal);
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('My Sheet');
            let headerLabel = [];
              this.headerValReport.forEach((item,index)=>{
                console.log(item)
                let maxLength = 15
                let cellLength = this.headerTitleReport[index].length;
                if (cellLength > maxLength) {
                    maxLength = cellLength;
                  }
                const header = {
                  header:this.headerTitleReport[index],
                  key:item,
                  width: maxLength
                }
                headerLabel.push(header);
              });
              console.log(headerLabel);
              console.log(this.itemsReport);
              // Add some data to the worksheet
              worksheet.columns = headerLabel;
              let headerRow = worksheet.getRow(1);
              headerRow.height = 30;
              headerRow.eachCell((cell) => {

                cell.font = {
                  bold: true,
                  color: { argb: 'FFFFFFFF' },
                  size: 14
                };
                cell.fill = {
                  type: 'pattern',
                  pattern:'solid',
                  fgColor:{argb:'FF0000FF'}
                };
              });
            const column = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
            for (const [index, item] of this.itemsReport.entries()) {
              console.log(item.create_date);
              const userImage = item.member_verify_photo;
              const photos = item.photos;
              // item.member_verify_photo =(item.member_verify_photo != undefined)?'':'-';
              // item.member_verify_photo ={
              //           text: 'รูปที่ผู้ตรวจ',
              //           hyperlink: item.member_verify_photo,
              //           tooltip: 'open'
              //         }
              item.photos =(item.photos != undefined)?'':'-';
              console.log(item)
              const dateTime = item.create_date.split(" ");
              const dateCreate = dateTime[0];
              const timeCreate = dateTime[1];
              item.create_date = dateCreate;
              item.time = timeCreate;

              const columnStatus = `H${index+2}`
              let colorBg = '';
              console.log(columnStatus)
              console.log(item.status)
              worksheet.addRow(item);
              switch (item.status) {
                case 'ข้ามจุด':
                  colorBg = 'FFFFFF00';
                  break;
                case 'เหตุการณ์ปกติ':
                  colorBg = 'FF00FF00';
                  break;
                case 'เหตุการณ์ไม่ปกติ':
                  colorBg = 'FFFF0000';
                  break;
                default:
                  colorBg = 'FFFFFFFF';
                  break;
              }
              // ws.getRow(index+2).font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true };
              worksheet.getCell(columnStatus).fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor:{ argb: colorBg}
                };

              // row.height = 100;
              if (photos){
                for (const [indexPhoto,photo] of photos.entries()) {
                  const url = decodeURI(photo)
                  // let dcryptedPhotos = await this.getImageTobase(photo)
                  // const myBase64ImagePhotos = "data:image/jpg;base64,"+dcryptedPhotos;
                  try {
                    const indexRow = index+2;
                    const positionPhoto = 13+indexPhoto;
                    console.log(positionPhoto)
                    const field = `${column[positionPhoto]}${indexRow}`;
                    console.log(field);
                    console.log(encodeURI(photo));
                    worksheet.getCell(field).value = {
                            text: `รูปเหตุการณ์ ${indexPhoto+1}`,
                            hyperlink: url,
                            tooltip: 'open'
                          }
                    // ws.getRow(index+2).font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true };
                    worksheet.getCell(field).font = {underline: true,color: { argb: 'FF0000FF' }};
                    // worksheet.addImage(imageId2,field);
                  } catch (e) {
                      console.error(e);
                  }
                }
              }

              if(userImage !== ""){
                try {
                  console.log(userImage)
                const urlProfile = decodeURI(userImage)

                const indexRow = index+2;
                const field = `${column[4]}${indexRow}`;
                console.log(field)
                worksheet.getCell(field).value = null;
                worksheet.getCell(field).value = {
                                                  text: 'รูปผู้ตรวจ',
                                                  hyperlink: urlProfile,
                                                  tooltip: 'open'
                                                }
                worksheet.getCell(field).font = {underline: true,color: { argb: 'FF0000FF' }};
                } catch (e) {
                    console.error(e);
                }
              }
            }

             // Generate the Excel file
            const buffer = await workbook.xlsx.writeBuffer();

            // Create a Blob from the buffer
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Create a link element
            const link = document.createElement('a');
            const customerName = $cookies.get("customer");
            // Set the href and download attributes of the link
            link.href = URL.createObjectURL(blob);
            link.download = customerName+'.xlsx';

            // Append the link to the body
            document.body.appendChild(link);

            // Trigger a click on the link
            link.click();

            // Remove the link from the body
            document.body.removeChild(link);

              // import("@/vendor/Export2Excel").then((excel) => {
              //   const list = this.itemsReport;
              //   const data = this.formatJson(this.headerVal, list);

              //   console.log("data=", data);

              //   excel.export_json_to_excel({
              //     header: this.headerTitle,
              //     data,
              //     filename: this.getExportFileName(),
              //     autoWidth: true,
              //     bookType: "xlsx",
              //   });
              // });
              console.log("EXPORT EXCEL");
            } else {
              this.$vs.notify({
                color: "warning",
                title: "Export Excel Report",
                text: "ไม่พบข้อมูล !",
              });
            }
        });
    },
    async getImageTobase(url){
      if(!this.isValidUrl(url)){
        return ""
      }else{
        return  await axios.get(url, {
                      responseType: "arraybuffer"
                    })
                    .then(response =>
                      Buffer.from(response.data, "binary").toString("base64")
                    );
      }
    },
    isValidUrl(string) {
      try {
        new URL(string);
        return true;
      } catch (err) {
        return false;
      }
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          const reportValue = v[j];
          if (this.isEmpty(reportValue)) return "-";
          if (Array.isArray(reportValue)) return reportValue.join();

          return reportValue;
        })
      );
    },
  },
  components: {
    Datepicker,
    "v-select": vSelect,
  },
  watch: {},
  async created() {
    this.roleInfo = $cookies.get("roleInfo");
    this.userInfo = $cookies.get("userInfo");
    console.log("[[this.userInfo]]", this.userInfo);

    if ($cookies.get("userInfo") === null) {
      this.$router.push("/pages/login").catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    }

    this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", false);
    const parameters = this.$route.query;

    const home = $cookies.get("home");
    console.log("home", home);

    this.houseInfo = $cookies.get("houseinfo");
    console.log("this.houseInfo=", this.houseInfo);

    // await this.getCustomer();
    this.listItems({ page: this.page });
  },
};
</script>

<style>
.con-exemple-prompt {
  padding: 10px;
  padding-bottom: 0px;
}
.vs-input {
  width: 100%;
  margin-top: 10px;
}
.page-item {
}
.demo-inline-spacing {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.modaltable tr td:first-child {
  min-width: 100px;
}

.fullscreen-v-img {
  z-index: 999999999 !important;
}

.notNorlmalCase {
  background-color: #962525 !important;
  color: #fff;
  min-width: 100px;
}

.norlmalCase {
  background-color: #0d9c44 !important;
  color: #fff;
  min-width: 150px;
}
</style>
